<template>
  <div id="new-report">
    <Hero/>
    <div class="new-report-section">
      <div class="header-section">
        <h1>Create New Report</h1>
        <button class="logout" v-on:click="logout()">Logout > </button>
      </div>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        data-app
      >
        <v-row>
           <v-col cols="12" md="2">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="form.date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.date"
              :allowed-dates="(date) => date < new Date(Date.now() - (1000 * 60 * 60 * 6)).toISOString().substr(0, 10)"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(form.date)"
                v-on:click="getFlows"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
         </v-col>

         <v-col cols="12" md="5">
          <v-select
            v-model="form.location_id"
            :items="locations"
            item-text="name"
            item-value="location_id"
            :rules="[v => !!v || 'Destination is required']"
            label="Destination"
            @change="getFlows"
            required
          ></v-select>
        </v-col>

        <v-col cols="12" md="2">
          <v-select
            v-model="form.rating"
            :items="[1,2,3,4,5]"
            :rules="[v => !!v || 'Rating is required']"
            label="Rating"
            required
          ></v-select>
        </v-col>
      </v-row>
       
       <v-row>
        <v-col cols="12" md="3">
          <v-select
            v-model="form.clarity"
            :items="['Clear', 'Slightly Off', 'Stained']"
            :rules="[v => !!v || 'Clarity is required']"
            label="Clarity"
            required
          ></v-select>
         </v-col>

        <v-col cols="12" md="3">
          
          <v-text-field
            v-model="form.temp"
            label="Temperature"
            hide-details
            single-line
            type="number"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="form.flow"
            label="Flow"
            hide-details
            single-line
            type="number"
          ></v-text-field>
        </v-col>
       </v-row>

       <v-row>
         <v-col cols="12" md="4">
          <v-select
            v-model="form.flies"
            :items="flies"
            multiple
            :rules="[v => !!v || 'Flies is required']"
            label="Flies"
            required
          ></v-select>

         </v-col>

         <v-col cols="12" md="4">
          <v-select
            v-model="form.hatches"
            :items="hatches"
            multiple
            :rules="[v => !!v || 'Hatches is required']"
            label="Hatches"
            required
          ></v-select>

         </v-col>

       </v-row>
        
        <v-row>
          <v-textarea
            v-model="form.description"
            label="Description"
            required
          ></v-textarea>
        </v-row>

        <v-btn
          depressed
          elevation="2"
          outlined
          rounded
          @click="submit"
        >
          Create
        </v-btn>
      </v-form>
      <router-link class="see-more" to="/dashboard"> &#60; Back to Reports </router-link>
     </div>
  </div>
</template>

<script>
import api from '@/api'
import destinations from '../data/destinations.json'
import Hero from '@/components/Hero.vue'
export default {
  components: { 
    Hero
  },
  data () {
    return {
      form: {
        clarity: '',
        date: new Date(Date.now() - (1000 * 60 * 60 * 30)).toISOString().substr(0, 10),
        description: '',
        flies: [],     
        flow: null,
        hatches: [],
        location_id: '',
        rating: 1,
        temp: null
      },
      flies: [],
      locations: destinations.map(d => d.rivers.map(r =>  {return { drainage_name: d.name, name: r.name, location_id: r.river_id, abbreviation: r.station_abbreviation }})).flat(1).sort(),
      modal: false,
      valid: true
    }
  },
  async created () {
    const getFlies = await api.get('/flies')
    this.flies = getFlies.flies.map(f => f.fly_name).sort()

    const getHatches = await api.get('/hatches')
    this.hatches = getHatches.hatches.map(f => f.hatch_name).sort()
  },
  methods: {
    submit() {
      let location = this.locations.find(l => l.location_id == this.form.location_id);
      this.form.location_name = location.name
      this.form.drainage_name = location.drainage_name
      api.post('/reports', this.form).then(() => this.$router.push('/dashboard'))
    }, 
    logout() {
      this.$cognitoAuth.logout();
      this.$router.push('/');
    },
    getFlows() {
      let formDate = new Date(this.form.date)
      let start_date = new Date(formDate.getTime() - (1000 * 60 * 60 * 24)).toISOString().substr(0, 10)
      let end_date = new Date(formDate.getTime() + (1000 * 60 * 60 * 24)).toISOString().substr(0, 10)
      let location = this.locations.find(l => l.location_id == this.form.location_id)
      this.form.abbreviation = location.abbreviation
      api.get('/flows?station_abbreviation=' + this.form.abbreviation + '&start_date=' + start_date + '&end_date=' + end_date).then(response => {
        this.form.flow = response[0].flow
      })
      }
  }
}
</script>


<style lang="scss">
#new-report {
  .new-report-section {
    width: 65%;
    margin: auto;
    padding-bottom: 50px;
    .header-section {
      margin: 50px 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    form {
      margin-bottom: 30px;
    }
    .logout {
      color: #3C6E71;
      font-weight: 600;
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 500px) {
    .new-report-section {
      width: 90%;
      margin: auto;
    }
  }
}
</style>